import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import gpcBrowserRepository from "../repositories/gpcBrowserRepository.js";

const languages = ref([]);
const isFetchingLanguages = ref(false);
export default function useLanguage() {
  async function fetchLanguagesAsync() {
    isFetchingLanguages.value = true;
    const response = await gpcBrowserRepository.getLanguagesAsync();
    languages.value = response.result;
    isFetchingLanguages.value = false;
    return response;
  }

  function isEnglish(languageId) {
    const existingLanguage = languages.value.find((f) => f.languageId == languageId);
    if (!existingLanguage) {
      return false;
    }
    return existingLanguage.languageCode.toLowerCase() === "en";
  }

  return {
    languages: computed(() => languages.value),
    isFetchingLanguages: computed(() => isFetchingLanguages.value),
    fetchLanguagesAsync,
    isEnglish
  };
}
