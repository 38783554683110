import appSettings from "../configuration/appSettings";

export async function httpGet(path, abortSignal = null) {
  try {
    let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("get", null, abortSignal));
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    }
    return response.json();
  } catch (error) {
    error.name == "AbortError" && console.log("The user aborted a request");
    throw error;
  }
}

export async function httpPost(path, body, abortSignal = null) {
  try {
    let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("post", body, abortSignal));
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    }
    return response.json();
  } catch (error) {
    console.log(error.name);
    throw error;
  }
}

export async function httpPut(path, body, abortSignal = null) {
  try {
    let response = await fetch(`${appSettings.API_URL}${path}`, getConfig("put", body, abortSignal));
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    }
    return response.json();
  } catch (error) {
    console.log(error.name);
    throw error;
  }
}

export async function httpCustom(path, config, abortSignal = null) {
  if (abortSignal) {
    config.signal = abortSignal;
  }
  return fetch(`${appSettings.API_URL}${path}`, config);
}

export function getFileName(response) {
  if (response != null && response.headers != null) {
    const header = response.headers.get("Content-Disposition");
    if (header) {
      return header.match(/filename=(.+);/)[1].replace(/(^")|("$)/g, "");
    }
  }
  return "File";
}

function getConfig(method, body = "", abortSignal = null) {
  let config = {
    headers: { "Content-Type": "application/json;charset=utf-8" },
  };
  if (abortSignal) {
    config.signal = abortSignal;
  }
  if (method === "get") {
    config.method = method.toUpperCase();
  }
  if (method === "post") {
    config.method = method.toUpperCase();
    config.body = JSON.stringify(body);
  }
  if (method === "put") {
    config.method = method.toUpperCase();
    config.body = JSON.stringify(body);
  }

  return config;
}
