export default function useGtm() {
  const _isGtmAvailable = Array.isArray(window.dataLayer) && (typeof window.gtag === 'function');

  function trackEvent(eventName, params) {
    if (_isGtmAvailable) {
        window.gtag("event", eventName, params);
    }
  }

  return {
    trackEvent: trackEvent,
  };
}
