<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <tooltip-info style="width: 46px" tooltipTitle="Advanced Search Options" :show="isNoResultsFound"></tooltip-info>
        <div class="input-group gs1-input-group">
          <broswer-search-config-dropdown></broswer-search-config-dropdown>
          <browser-language-dropdown @onChange="handleChangeLanguageSelect"></browser-language-dropdown>
          <browser-publication-dropdown :languageId="selectedLanguageId" @onChange="handleChangePublicationSelect"></browser-publication-dropdown>
          <div class="w-100 d-block d-sm-none"></div>
          <browser-search @onSearch="handleSearch"></browser-search>
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-md-5 d-sm-none d-md-block"></div>
      <div class="col-2 col-sm-2 col-md-2 d-flex justify-content-center">
        <div v-show="isLoading" class="search-spinner spinner-border gs1-color-orange-accessible mx-2 fs-6" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="col-md-5 d-sm-none d-md-block"></div>
    </div>
    <div class="row">
      <div class="col-12" v-show="isNoResultsFound">
        <div class="fw-bold">Sorry, no search results found!</div>
        <div class="fs-6">
          We were unable to find any result for the terms you have typed in. Please refine your search in the advanced search options menu.
        </div>
        <div class="fs-6 mt-3">
          <div>Search Tips:</div>
          <ul>
            <li class="m-0">Make sure that all words are spelled correctly</li>
            <li class="m-0">Use a more generic search term (Trademarked names are generally not used in the Classification)</li>
            <li class="m-0">Try to use a broad criteria or partial word searches</li>
          </ul>
        </div>
        <div class="fs-6">
          <div>Still can't find what you are looking for?</div>
          <ul>
            <li class="m-0">
              <span>
                If you are from the country of Egypt, please email
                <a href="mailto:einvoice@gs1eg.org?subject=GPC Browser&body=Please provide product name or description (image optional)."
                  >einvoice@gs1eg.org</a
                >
                for help.
              </span>
            </li>
            <li class="m-0">
              <span>
                Other countries please email
                <a href="mailto:helpdesk@gs1.org?subject=GPC Browser&body=Please provide product name or description (image optional)."
                  >helpdesk@gs1.org</a
                >
                for help.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12" v-show="treeData.length > 0">
        <div :key="treeKey">
          <browser-tree-item v-for="item in treeData" :key="item.code" :item="item"></browser-tree-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrowserTreeItem from "./BrowserTreeItem.vue";
import BrowserLanguageDropdown from "./BrowserLanguageDropdown.vue";
import BrowserPublicationDropdown from "./BrowserPublicationDropdown.vue";
import useTree from "../../composables/useTree";
import useSearch from "../../composables/useSearch";
import BrowserSearch from "./BrowserSearch.vue";
import { computed, nextTick, ref } from "@vue/runtime-core";
import BroswerSearchConfigDropdown from "./BroswerSearchConfigDropdown.vue";
import useSearchConfigurationSingleton from "../../composables/useSearchConfigurationSingleton";
import useLanguage from "../../composables/useLanguage";
import TooltipInfo from "../shared/TooltipInfo.vue";
export default {
  components: { BrowserTreeItem, BrowserLanguageDropdown, BrowserPublicationDropdown, BrowserSearch, BroswerSearchConfigDropdown, TooltipInfo },
  emits: ["onLanguageChange", "onPublicationChange"],
  setup(props, { emit }) {
    const { languages } = useLanguage();
    const { treeData, fetchPublishedSchemaTreeAsync, isFetchingSegmentFamilyClasses, isFetchingTree, clearTheTree, abortTreeFetching } = useTree();
    const { searchAsync } = useSearch();
    const { searchConfiguration } = useSearchConfigurationSingleton();

    let fetchTreePromise = Promise.resolve();
    const selectedLanguageId = ref(0);
    const treeKey = ref(0);
    const isSearching = ref(false);

    const isLoading = computed(() => {
      return isSearching.value || isFetchingSegmentFamilyClasses.value;
    });
    const isNoResultsFound = computed(() => {
      return !isLoading.value && !isFetchingTree.value && treeData.value.every((f) => f.isOutOfSearch);
    });

    const handleChangeLanguageSelect = async (languageId) => {
      selectedLanguageId.value = languageId;
      emit(
        "onLanguageChange",
        languages.value.find((lang) => lang.languageId == languageId)
      );
    };

    const handleChangePublicationSelect = async (publication) => {
      if (publication != null) {
        abortTreeFetching();
        await fetchTreePromise;
        fetchTreePromise = fetchPublishedSchemaTreeAsync(publication.publicationId);
      } else {
        abortTreeFetching();
        await fetchTreePromise;
        await nextTick();
        clearTheTree();
      }
      emit("onPublicationChange", publication);
    };

    const handleSearch = (text) => {
      isSearching.value = true;
      setTimeout(async () => {
        if (searchConfiguration.includeAttributesAndValues) {
          await fetchTreePromise;
        }
        await searchAsync(treeData, text, searchConfiguration);
        isSearching.value = false;
        ++treeKey.value; //trigger rerender component
      }, 100);
    };

    return {
      treeData,
      selectedLanguageId,
      handleChangeLanguageSelect,
      handleChangePublicationSelect,
      handleSearch,
      isSearching,
      treeKey,
      isLoading,
      isNoResultsFound,
    };
  },
};
</script>
<style scoped>
.search-spinner {
  height: 1.5rem;
  width: 1.5rem;
  border-width: 0.2em;
}
.publication-name {
  font-weight: 500;
}
</style>
