import appSettings from "../configuration/appSettings";
import { getFileName, httpCustom, httpGet } from "../services/httpService";

export default (function () {
  async function getLanguagesAsync() {
    return httpGet(`/browser/language/all`);
  }

  async function getPublicationsAsync(languageId) {
    return httpGet(`/browser/publication?languageId=${languageId}`);
  }

  async function getPreviousPublicationAsync(publicationId) {
    return httpGet(`/browser/publication/previous?publicationId=${publicationId}`);
  }

  async function isLatestPublicationAsync(publicationId) {
    return httpGet(`/browser/publication/islatest?publicationId=${publicationId}`);
  }

  async function getSegmentsAsync(publicationId, abortSignal) {
    return httpGet(`/browser/segment?publicationId=${publicationId}`, abortSignal);
  }

  async function getFamiliesAsync(publicationId, abortSignal) {
    return httpGet(`/browser/family?publicationId=${publicationId}`, abortSignal);
  }

  async function getClassesAsync(publicationId, abortSignal) {
    return httpGet(`/browser/class?publicationId=${publicationId}`, abortSignal);
  }

  async function getBricksAsync(publicationId, abortSignal) {
    return httpGet(`/browser/brick?publicationId=${publicationId}`, abortSignal);
  }

  async function getAttributesAsync(publicationId, abortSignal) {
    return httpGet(`/browser/attribute?publicationId=${publicationId}`, abortSignal);
  }

  async function getAttributeValuesAsync(publicationId, abortSignal) {
    return httpGet(`/browser/attribute-value?publicationId=${publicationId}`, abortSignal);
  }

  async function getRelationsAsync(publicationId, abortSignal) {
    return httpGet(`/browser/brick-attribute-value?publicationId=${publicationId}`, abortSignal);
  }

  async function exportAsJsonAsync(publicationId) {
    let url = `/browser/download/publication/${publicationId}/json`;
    let response = await httpCustom(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText, name: "Empty" };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportAsXmlAsync(publicationId) {
    let url = `/browser/download/publication/${publicationId}/xml`;
    let response = await httpCustom(url, {
      method: "GET",
      headers: { "Content-Type": "application/xml;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText, name: "Empty" };
    } else {
      let data = await response.text();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportAsXlsxAsync(publicationId) {
    let url = `/browser/download/publication/${publicationId}/xlsx`;
    let response = await httpCustom(url, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText, name: "Empty" };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportDeltaAsJsonAsync(languageId, publicationId = null) {
    let response = await httpCustom(`/publication/delta/json?languageId=${languageId}&publicationId=${publicationId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportDeltaAsXlsxAsync(languageId, publicationId = null) {
    let response = await httpCustom(`/publication/delta/xlsx?languageId=${languageId}&publicationId=${publicationId}`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  function getDownloadPublicationReleaseNoteAttachmentUrl(publicationId) {
    return `${appSettings.API_URL}/publication/attachment/download?publicationId=${publicationId}`;
  }

  return Object.freeze({
    getLanguagesAsync,
    getPublicationsAsync,
    getPreviousPublicationAsync,
    isLatestPublicationAsync,
    getSegmentsAsync,
    getFamiliesAsync,
    getClassesAsync,
    getBricksAsync,
    getAttributesAsync,
    getAttributeValuesAsync,
    getRelationsAsync,
    exportAsJsonAsync,
    exportAsXmlAsync,
    exportAsXlsxAsync,
    exportDeltaAsJsonAsync,
    exportDeltaAsXlsxAsync,
    getDownloadPublicationReleaseNoteAttachmentUrl,
  });
})();
