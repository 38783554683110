<template>
  <button id="PublicationDropdown" class="btn btn-outline-secondary gs1-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <span class="publication-label">{{ selectedPublicationName }}</span>
    <b-icon-chevron-down class="ms-1 gs1-color-blue"></b-icon-chevron-down>
  </button>
  <ul class="dropdown-menu gs1-dropdown-menu">
    <li v-for="publication in publications" :key="publication.publicationId" @click.prevent="selectPublication(publication)" class="m-0">
      <a class="dropdown-item" href="#">{{ publication.publicationName }}</a>
    </li>
  </ul>
</template>

<script>
import { computed, ref, watch } from "@vue/runtime-core";
import useLanguage from "../../composables/useLanguage";
import useGtm from "../../composables/useGtm";
import { BIconChevronDown } from "bootstrap-icons-vue";
import usePublication from "../../composables/usePublication";
import { useRoute } from "vue-router";
export default {
  components: { BIconChevronDown },
  emits: ["onChange"],
  props: {
    languageId: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const { languages } = useLanguage();
    const { publications, fetchPublicationsAsync } = usePublication();
    const { trackEvent } = useGtm();
    const route = useRoute();
    let isRouteParamHandled = false

    const selectedPublication = ref(null);
    const selectedPublicationName = computed(() => (selectedPublication.value == null ? "..." : selectedPublication.value.publicationName));

    watch(
      () => props.languageId,
      async (id) => {
        await fetchPublicationsAsync(id);
        if (publications.value.length > 0) {
          selectedPublication.value = publications.value[0];
          handleQueryParamPublication();
          trackEvent("gpc_publication_select", {
            languageCode: languages.value.find((f) => f.languageId == props.languageId).languageCode.toLowerCase(),
            publication: selectedPublication.value.publicationName,
          });
        } else {
          selectedPublication.value = null;
          emit("onChange", selectedPublication.value);
        }
      }
    );

    watch(selectedPublication, (currPublication, prevPublication) => {
      emit("onChange", currPublication);
      if (currPublication != null && currPublication != prevPublication) {
        trackEvent("gpc_publication_select", {
          languageCode: languages.value.find((f) => f.languageId == props.languageId).languageCode.toLowerCase(),
          publication: currPublication.publicationName,
        });
      }
    });

    function selectPublication(publication) {
      selectedPublication.value = publication;
    }

    const handleQueryParamPublication = () => {
      if(isRouteParamHandled) return;
      const publicationTypes = ["gdsn", "gpc"];
      const publicationParam = route.query.publ;
      if (!publicationParam || !publicationTypes.includes(publicationParam.toLowerCase())) return;
      const isGDSN = publicationParam.toLowerCase() == publicationTypes[0];
      const matchedPublication = publications.value.find((f) => f.isGDSN == isGDSN);
      if (matchedPublication) {
        selectedPublication.value = matchedPublication;
      }
      isRouteParamHandled = true;
    };

    return {
      publications,
      selectedPublicationName,
      selectPublication,
    };
  },
};
</script>
<style scoped>
#PublicationDropdown {
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.publication-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
