import { reactive } from "@vue/reactivity";

const searchConfiguration = reactive({
  searchInCode: true,
  searchInTitle: true,
  searchInDefinitionIncludes: true,
  searchInDefinitionExcludes: false,
  searchInWordsCombination: true,
  includeAttributesAndValues: true,
  trimSearchResults: true,
  exactWording: false,
});

export default function useSearchConfigurationSingleton() {
  return {
    searchConfiguration: searchConfiguration,
  };
}
