import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import enums from "../common/enums";

export default function useSearch() {
  String.prototype.ignoreAccents = function () {
    return this.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  };
  String.prototype.replaceAt = function (index, replacementLength, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacementLength);
  };
  String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  const isSearching = ref(false);

  async function searchAsync(treeData, text, searchConfig) {
    if (text == null || text == "") {
      cleanupSearch(treeData);
      return;
    }

    cleanupSearch(treeData);
    isSearching.value = true;
    search(treeData, text.toString().trim(), searchConfig);
    isSearching.value = false;
  }

  function search(treeData, textToSearch, searchConfig) {
    treeData.value.forEach((segment) => {
      let foundArray = [];
      foundArray.push(handleSearch(segment, textToSearch, searchConfig));
      segment.childs.forEach((family) => {
        foundArray.push(handleSearch(family, textToSearch, searchConfig, [segment]));
        family.childs.forEach((clas) => {
          foundArray.push(handleSearch(clas, textToSearch, searchConfig, [segment, family]));
          clas.childs.forEach((brick) => {
            foundArray.push(handleSearch(brick, textToSearch, searchConfig, [segment, family, clas]));
            brick.childs.forEach((attribute) => {
              foundArray.push(handleSearch(attribute, textToSearch, searchConfig, [segment, family, clas, brick]));
              attribute.childs.forEach((attributeValue) => {
                foundArray.push(handleSearch(attributeValue, textToSearch, searchConfig, [segment, family, clas, brick, attribute]));
              });
            });
          });
        });
      });
      if (foundArray.find((f) => f === true)) {
        segment.isOutOfSearch = false;
      } else {
        segment.isOutOfSearch = true;
      }
    });
  }

  const handleSearch = (itemToSearchIn, textToSearch, searchConfig, parents = []) => {
    if (trySearchInItem(itemToSearchIn, textToSearch, searchConfig)) {
      parents.forEach((parent) => (parent.isOpen = true));
      if (searchConfig.trimSearchResults) {
        parents.forEach((parent) => (parent.isOutOfSearch = false));
        itemToSearchIn.isSearchedItem = true;
      }
      return true;
    } else {
      itemToSearchIn.isOpen = false;
      if (searchConfig.trimSearchResults) {
        if (parents.length && parents.some((s) => s.isSearchedItem)) {
          itemToSearchIn.isOutOfSearch = false;
        } else {
          itemToSearchIn.isOutOfSearch = true;
        }
      }
      return false;
    }
  };

  function cleanupSearch(treeData) {
    const boolProperties = ["isOpen", "isOutOfSearch", "isSearchedItem"];
    const stringProperties = ["codeSearch", "titleSearch", "definitionSearch", "definitionExcludesSearch"];
    const cleanupProperties = (item) => {
      boolProperties.forEach((prop) => (item[prop] = false));
      stringProperties.forEach((prop) => (item[prop] = null));
    };

    treeData.value.forEach((segment) => {
      cleanupProperties(segment);
      segment.childs.forEach((family) => {
        cleanupProperties(family);
        family.childs.forEach((clas) => {
          cleanupProperties(clas);
          clas.childs.forEach((brick) => {
            cleanupProperties(brick);
            brick.childs.forEach((attribute) => {
              cleanupProperties(attribute);
              attribute.childs.forEach((attributeValue) => {
                cleanupProperties(attributeValue);
              });
            });
          });
        });
      });
    });
  }

  function trySearchInItem(item, text, searchConfig) {
    if (
      !searchConfig.includeAttributesAndValues &&
      (item.level == enums.ClassificationLevel.Attribute || item.level == enums.ClassificationLevel.Value)
    ) {
      return false;
    }

    return (
      trySearchInCode(item, text, searchConfig) ||
      trySearchInTitle(item, text, searchConfig) ||
      trySearchInDefinitionIncludes(item, text, searchConfig) ||
      trySearchInDefinitionExcludes(item, text, searchConfig)
    );
  }

  const trySearchInCode = (item, text, searchConfig) => {
    if (searchConfig.searchInCode && item.code.toString().includes(text)) {
      item.codeSearch = item.code.toString().replace(text, `<span class="text-white bg-dark">${text}</span>`);
      return true;
    }
    return false;
  };

  const trySearchInTitle = (item, text, searchConfig) => {
    if (searchConfig.searchInTitle) {
      item.titleSearch = getSearchResultBasedOnConfig(item.title, text, searchConfig);
      if (item.titleSearch !== "") {
        return true;
      }
    }
    return false;
  };

  const trySearchInDefinitionIncludes = (item, text, searchConfig) => {
    if (searchConfig.searchInDefinitionIncludes && item.definition != null && item.definition !== "") {
      item.definitionSearch = getSearchResultBasedOnConfig(item.definition, text, searchConfig);
      if (item.definitionSearch !== "") {
        return true;
      }
    }
    return false;
  };

  const trySearchInDefinitionExcludes = (item, text, searchConfig) => {
    if (searchConfig.searchInDefinitionExcludes && item.definitionExcludes != null && item.definitionExcludes !== "") {
      item.definitionExcludesSearch = getSearchResultBasedOnConfig(item.definitionExcludes, text, searchConfig);
      if (item.definitionExcludesSearch !== "") {
        return true;
      }
    }
    return false;
  };

  const getSearchResultBasedOnConfig = (searchInText, text, searchConfig) => {
    if (searchConfig.exactWording) {
      return getExactSearchedText(searchInText, text);
    }
    if (searchConfig.searchInWordsCombination) {
      let keys = {};
      let words = text.split(" ").map((m) => m.trim());
      let searchedText = getSearchedText(searchInText, words, keys);
      return Object.keys(keys).length >= words.length ? searchedText : "";
    }
    return getSearchedText(searchInText, [text]);
  };

  const getSearchedText = (searchInText, words, keyWordsDictionaryToReplace = {}) => {
    words.forEach((word, index) => {
      const indexOfMatchedWord = searchInText.ignoreAccents().toLowerCase().indexOf(word.ignoreAccents().toLowerCase());
      if (indexOfMatchedWord > -1) {
        let tempKey = `$${index}`;
        let capitalizedWord = word.capitalizeFirstLetter();
        if (searchInText.ignoreAccents().indexOf(capitalizedWord.ignoreAccents()) > -1) {
          keyWordsDictionaryToReplace[tempKey] = `<span class="text-white bg-dark">${capitalizedWord}</span>`;
          searchInText = searchInText.replaceAt(indexOfMatchedWord, capitalizedWord.length, tempKey);
          return;
        }
        keyWordsDictionaryToReplace[tempKey] = `<span class="text-white bg-dark">${word}</span>`;
        searchInText = searchInText.replaceAt(indexOfMatchedWord, word.length, tempKey);
      }
    });

    if (Object.keys(keyWordsDictionaryToReplace).length) {
      for (const [key, value] of Object.entries(keyWordsDictionaryToReplace)) {
        searchInText = searchInText.replace(key, value);
      }
    } else {
      searchInText = "";
    }
    return searchInText;
  };

  const getExactSearchedText = (searchInText, wordToSearch) => {
    const indexOfMatchedWord = searchInText.ignoreAccents().toLowerCase().indexOf(wordToSearch.ignoreAccents().toLowerCase());
    if (indexOfMatchedWord < 0) {
      return "";
    }
    if (indexOfMatchedWord == 0 && searchInText.charAt(wordToSearch.length).ignoreAccents().match(/[a-z]/i) == null) {
      return searchInText.replaceAt(indexOfMatchedWord, wordToSearch.length, `<span class="text-white bg-dark">${wordToSearch}</span>`);
    }
    if (
      indexOfMatchedWord == searchInText.length - wordToSearch.length &&
      searchInText
        .charAt(searchInText.length - wordToSearch.length - 1)
        .ignoreAccents()
        .match(/[a-z]/i) == null
    ) {
      return searchInText.replaceAt(indexOfMatchedWord, wordToSearch.length, `<span class="text-white bg-dark">${wordToSearch}</span>`);
    }
    if (
      searchInText
        .charAt(indexOfMatchedWord - 1)
        .ignoreAccents()
        .match(/[a-z]/i) == null &&
      searchInText
        .charAt(indexOfMatchedWord + wordToSearch.length)
        .ignoreAccents()
        .match(/[a-z]/i) == null
    ) {
      return searchInText.replaceAt(indexOfMatchedWord, wordToSearch.length, `<span class="text-white bg-dark">${wordToSearch}</span>`);
    }

    return "";
  };

  return {
    isSearching: computed(() => isSearching.value),
    searchAsync,
  };
}
