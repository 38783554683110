<template>
  <div class="wrapper">
    <the-top-header></the-top-header>
    <the-header class="site-header"></the-header>
    <div class="site-body">
      <the-breadcrumb></the-breadcrumb>
      <router-view />
    </div>
    <the-footer class="site-footer"></the-footer>
  </div>
  <the-scroll-top-button></the-scroll-top-button>
</template>

<script>
import TheBreadcrumb from "./components/TheBreadcrumb.vue";
import TheFooter from "./components/TheFooter.vue";
import TheHeader from "./components/TheHeader.vue";
import TheTopHeader from "./components/TheTopHeader.vue";
import TheScrollTopButton from "./components/TheScrollTopButton.vue";
export default {
  components: { TheHeader, TheFooter, TheBreadcrumb, TheTopHeader,TheScrollTopButton },
  name: "App",
};
</script>
<style >
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.site-header,
.site-footer {
  flex-shrink: 0;
}

.site-body {
  flex-grow: 1;
}
</style>
