<template>
  <a class="btn gs1-button" :href="props.link">{{ props.text }}<b-icon-arrow-right-short></b-icon-arrow-right-short></a>
</template>
<script>
import { BIconArrowRightShort } from "bootstrap-icons-vue";
export default {
  components: { BIconArrowRightShort },
  props: {
    link: String,
    text: String,
  },
  setup(props) {
    return { props };
  },
};
</script>

<style scoped>
a.gs1-button > svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}
a.gs1-button:hover > svg {
  transform: translateX(25%);
  transition: all 0.2s ease;
}
</style>
