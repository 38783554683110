<template>
  <a class="gs1-link" :href="props.link">{{ props.text }}<b-icon-arrow-right-short></b-icon-arrow-right-short></a>
</template>
<script>
import { BIconArrowRightShort } from "bootstrap-icons-vue";
export default {
  components: { BIconArrowRightShort },
  props: {
    link: String,
    text: String,
  },
  setup(props) {
    return { props };
  },
};
</script>

<style scoped>
a.gs1-link {
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 25px;
  padding: 0;
  text-decoration: none;
}
a.gs1-link > svg {
  width: 1.875rem;
  height: 1.875rem;
}
a.gs1-link:hover > svg {
  transform: translateX(25%);
  transition: all 0.2s ease;
}
</style>
