<template>
  <button class="btn btn-outline-secondary gs1-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <BIconSliders class=" gs1-color-blue fs-5"></BIconSliders>
  </button>
  <div class="dropdown-menu gs1-dropdown-menu">
    <div class="form-check mx-2">
      <label class="form-check-label" for="SearchInCodeCheck">Search in Code</label>
      <input v-model="searchConfiguration.searchInCode" class="form-check-input gs1-check-input" type="checkbox" id="SearchInCodeCheck" />
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label" for="SearchInTitleCheck">Search in Title</label>
      <input v-model="searchConfiguration.searchInTitle" class="form-check-input gs1-check-input" type="checkbox" id="SearchInTitleCheck" />
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label" for="SearchInIncludesCheck">Search in Definition (Includes)</label>
      <input v-model="searchConfiguration.searchInDefinitionIncludes" class="form-check-input gs1-check-input" type="checkbox" id="SearchInIncludesCheck" />
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label" for="SearchInExcludesCheck">Search in Definition (Excludes)</label>
      <input v-model="searchConfiguration.searchInDefinitionExcludes" class="form-check-input gs1-check-input" type="checkbox" id="SearchInExcludesCheck" />
    </div>
    <div class="form-check mx-2">
      <label class="form-check-label" for="SearchInAttributesCheck">Include Attributes/Values</label>
      <input v-model="searchConfiguration.includeAttributesAndValues" class="form-check-input gs1-check-input" type="checkbox" id="SearchInAttributesCheck" />
    </div>
    <hr class="dropdown-divider" />
    <div class="trimmed-search form-check mx-2">
      <input v-model="searchConfiguration.trimSearchResults" class="form-check-input gs1-check-input" type="checkbox" value="" id="TrimmedSearchCheck" />
      <label class="form-check-label" for="TrimmedSearchCheck">Trim Search Results</label>
    </div>
    <div class="form-check mx-2">
      <input v-model="searchConfiguration.searchInWordsCombination" class="form-check-input gs1-check-input" type="checkbox" value="" id="SearchInWordsCombinationCheck" />
      <label class="form-check-label" for="SearchInWordsCombinationCheck">Search in words combination</label>
    </div>
    <div class="form-check mx-2">
      <input v-model="searchConfiguration.exactWording" class="form-check-input gs1-check-input" type="checkbox" value="" id="ExactWordingCheck" />
      <label class="form-check-label" for="ExactWordingCheck">Exact wording</label>
    </div>
  </div>
</template>

<script>
import { BIconSliders } from "bootstrap-icons-vue";
import useSearchConfigurationSingleton from "../../composables/useSearchConfigurationSingleton";
export default {
  components: { BIconSliders },
  emits: ["onClose"],
  setup(props, { emit }) {
    const { searchConfiguration } = useSearchConfigurationSingleton();
    const close = () => {
      emit("onClose");
    };

    return { searchConfiguration, close };
  },
};
</script>
