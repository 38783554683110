import { ref } from "@vue/reactivity";
import { computed, nextTick } from "@vue/runtime-core";
import enums from "../common/enums";
import gpcBrowserRepository from "../repositories/gpcBrowserRepository";

const isFetchingTree = ref(false);
const isFetchingSegmentFamilyClasses = ref(true);
const isFetchingBricks = ref(true);
const isFetchingAttributesAndValues = ref(true);

export default function useTree() {
  const treeData = ref([]);
  let abortController = new AbortController();

  function clearTheTree() {
    treeData.value = [];
  }

  function abortTreeFetching() {
    abortController.abort();
  }

  async function fetchPublishedSchemaTreeAsync(publicationId) {
    try {
      abortController = new AbortController();
      isFetchingTree.value = true;
      treeData.value = [];
      await nextTick();
      const segmentPromise = gpcBrowserRepository.getSegmentsAsync(publicationId, abortController.signal);
      const familyPromise = gpcBrowserRepository.getFamiliesAsync(publicationId, abortController.signal);
      const classPromise = gpcBrowserRepository.getClassesAsync(publicationId, abortController.signal);
      const brickPromise = gpcBrowserRepository.getBricksAsync(publicationId, abortController.signal);
      const attributePromise = gpcBrowserRepository.getAttributesAsync(publicationId, abortController.signal);
      const attrValuePromise = gpcBrowserRepository.getAttributeValuesAsync(publicationId, abortController.signal);
      const relationPromise = gpcBrowserRepository.getRelationsAsync(publicationId, abortController.signal);
      isFetchingSegmentFamilyClasses.value = true;
      addSegments(await segmentPromise);
      addFamilies(await familyPromise);
      addClasses(await classPromise);
      isFetchingSegmentFamilyClasses.value = false;
      isFetchingBricks.value = true;
      const bricksResponse = await brickPromise;
      addBricks(bricksResponse.result);
      isFetchingBricks.value = false;
      isFetchingAttributesAndValues.value = true;
      const attributesResponse = await attributePromise;
      const attributeValuesResponse = await attrValuePromise;
      const relationsResponse = await relationPromise;
      addAttributesAndValues(bricksResponse.result, attributesResponse.result, attributeValuesResponse.result, relationsResponse.result);
      isFetchingAttributesAndValues.value = false;
      isFetchingTree.value = false;
    } catch (err) {
      if (err.name != "AbortError") throw err;
    }
  }

  function addSegments(response) {
    treeData.value = response.result;
  }
  function addFamilies(response) {
    let familyDictionary = mapToDictionary(response.result, "parentCode");
    treeData.value.forEach((segment) => {
      if (familyDictionary[segment.code] != null) {
        segment.childs.push(...familyDictionary[segment.code]);
      }
    });
  }
  function addClasses(response) {
    let classDictionary = mapToDictionary(response.result, "parentCode");
    treeData.value.forEach((segment) => {
      segment.childs.forEach((family) => {
        if (classDictionary[family.code] != null) {
          family.childs.push(...classDictionary[family.code]);
        }
      });
    });
  }

  function addBricks(items) {
    let brickDictionary = mapToDictionary(items, "parentCode");
    treeData.value.forEach((segment) => {
      segment.childs.forEach((family) => {
        family.childs.forEach((clas) => {
          if (brickDictionary[clas.code] != null) {
            clas.childs.push(...brickDictionary[clas.code]);
          }
        });
      });
    });
  }

  function addAttributesAndValues(bricks, attributes, values, relations) {
    let brickDictionary = Object.assign({}, ...bricks.map((x) => ({ [x.code]: x })));
    let attributeDictionary = Object.assign({}, ...attributes.map((x) => ({ [x.code]: x })));
    let valueDictionary = Object.assign({}, ...values.map((x) => ({ [x.code]: x })));
    relations.forEach((rel) => {
      if (
        brickDictionary[rel.brickCode] != null &&
        attributeDictionary[rel.attributeCode] != null &&
        valueDictionary[rel.attributeValueCode] != null
      ) {
        let relatedAttribute = brickDictionary[rel.brickCode].childs.find((f) => f.code == rel.attributeCode);
        if (!relatedAttribute) {
          relatedAttribute = Object.assign({}, attributeDictionary[rel.attributeCode]);
          relatedAttribute.childs = [];
          brickDictionary[rel.brickCode].childs.push(relatedAttribute);
        }
        let relatedValue = relatedAttribute.childs.find((f) => f.code == rel.attributeValueCode);
        if (!relatedValue) {
          relatedAttribute.childs.push(Object.assign({}, valueDictionary[rel.attributeValueCode]));
          relatedAttribute.childs.sort((a, b) => a.title.localeCompare(b.title));
        }
      }
    });
  }

  function isLoading(level) {
    switch (level) {
      case enums.ClassificationLevel.Brick:
        return true;
      case enums.ClassificationLevel.Attribute:
        return true;
      case enums.ClassificationLevel.AttributeValue:
        return true;
      default:
        return false;
    }
  }

  function getLevelTitle(level) {
    if (level == enums.ClassificationLevel.Value) {
      return "Attribute Value";
    }
    return Object.keys(enums.ClassificationLevel).find((key) => enums.ClassificationLevel[key] == level);
  }

  function mapToDictionary(array, propName) {
    return array.reduce((prev, curr) => {
      if (prev[curr[propName]] == null) {
        prev[curr[propName]] = [curr];
      } else {
        prev[curr[propName]].push(curr);
      }
      return prev;
    }, {});
  }

  return {
    treeData: computed(() => treeData.value),
    isFetchingTree: computed(() => isFetchingTree.value),
    isFetchingSegmentFamilyClasses: computed(() => isFetchingSegmentFamilyClasses.value),
    isFetchingBricks: computed(() => isFetchingBricks.value),
    isFetchingAttributesAndValues: computed(() => isFetchingAttributesAndValues.value),
    fetchPublishedSchemaTreeAsync,
    getLevelTitle,
    isLoading,
    clearTheTree,
    abortTreeFetching,
  };
}
