<template>
  <div id="TooltipInfo" :title="tooltipTitle" @blur="handleBlur" tabindex="-1"></div>
</template>

<script>
import { onMounted, watchEffect } from "@vue/runtime-core";
import { Tooltip } from "bootstrap";
export default {
  props: {
    tooltipTitle: String,
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let tooltip;
    let isTooltipFocused = false;
    const showTooltip = () => {
      isTooltipFocused = false;
      const tooltipEl = document.getElementById("TooltipInfo");
      tooltip = new Tooltip(tooltipEl, { trigger: "focus" });
      setTimeout(() => {
        tooltipEl.focus();
        isTooltipFocused = true;
      }, 100);
    };
    const disposeTooltip = (item, isFocused) => {
      if (isFocused && item && item.tip) {
        item.dispose();
      }
    };

    onMounted(() => {
      showTooltip();
    });

    watchEffect(() => {
      if (props.show) {
        showTooltip();
      }
    });

    function handleBlur() {
      disposeTooltip(tooltip, isTooltipFocused);
    }

    return { handleBlur };
  },
};
</script>
