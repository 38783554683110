// DEV ENV
// export default {
//   ENVIRONMENT_NAME: "Development",
//   MAIN_LANGUAGE_CODE: "EN",
//   API_URL: process.env.NODE_ENV == "development" ? "https://localhost:44356/api" : "https://gpc-api-dev.gs1.org/api",
//   SSO_REDIRECT_URL: process.env.NODE_ENV == "development" ? "https://localhost:9551" : "https://gpc-browser-dev.gs1.org",
//   SSO_OPEN_ID_PROVIDER: process.env.NODE_ENV == "development" ? "https://gs1sso-dev.gs1.org" : "https://gs1sso.gs1.org" ,
//   SSO_CLIENT_ID: process.env.NODE_ENV == "development" ? "GPCUIDEV" : "GPCUIPROD",
//   SESSION_REFRESH_TIME_TO_EXPIRE: 30,
//   SESSION_PROMPT_TIME_TO_EXPIRE: "70%",
// };

// STAGING ENV
// export default {
//   ENVIRONMENT_NAME: "Staging",
//   MAIN_LANGUAGE_CODE: "EN",
//   API_URL: "https://gpc-api-dev.gs1.org/api",
//   SSO_REDIRECT_URL: "https://gpc-browser-dev.gs1.org",
//   SSO_OPEN_ID_PROVIDER: "https://gs1sso-dev.gs1.org" ,
//   SSO_CLIENT_ID: "GPCUIDEV",
//   SESSION_REFRESH_TIME_TO_EXPIRE: 30,
//   SESSION_PROMPT_TIME_TO_EXPIRE: "70%",
// };


// PROD ENV
export default {
  ENVIRONMENT_NAME: "Production",
  MAIN_LANGUAGE_CODE: "EN",
  API_URL: "https://gpc-api.gs1.org/api",
  SSO_REDIRECT_URL: "https://gpc-browser.gs1.org",
  SSO_OPEN_ID_PROVIDER: "https://gs1sso.gs1.org" ,
  SSO_CLIENT_ID: "GPCUIPROD",
  SESSION_REFRESH_TIME_TO_EXPIRE: 30,
  SESSION_PROMPT_TIME_TO_EXPIRE: "70%",
};
