<template>
  <button @click="scrollToTop" id="ScrollTopButton" :style="{ display: showButton ? 'inline-block' : 'none' }"><BIconChevronUp class="gs1-color-blue"></BIconChevronUp></button>
</template>

<script>
import { BIconChevronUp } from "bootstrap-icons-vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
export default {
  components: { BIconChevronUp },
  setup() {
    const scTimer = ref(0);
    const scY = ref(0);

    const showButton = computed(() => {
      return scY.value > 300;
    });

    onMounted(() => {
      window.addEventListener("scroll", () => {
        if (scTimer.value) return;
        scTimer.value = setTimeout(() => {
          scY.value = window.scrollY;
          clearTimeout(scTimer.value);
          scTimer.value = 0;
        }, 100);
      });
    });

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    return { scrollToTop, showButton };
  },
};
</script>

<style scoped>
#ScrollTopButton {
  border: 1px solid var(--gs1-color-ui-2);
  background-color: var(--gs1-color-ui-1);
  box-shadow: 0 2px 3px 0 rgb(0 44 108 / 20%);
  border-radius: 100px;
  transition: bottom 0.2s ease;
  bottom: 20px;
  cursor: pointer;
  display: none;
  height: 70px;
  position: fixed;
  right: 20px;
  width: 70px;
  z-index: 300;
}
#ScrollTopButton:hover {
  box-shadow: 0px 10px 30px rgb(0 44 108 / 20%);
  bottom: 24px;
  opacity: 0.8;
}
</style>
