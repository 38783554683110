export default (function () {
  const ClassificationLevel = {
    Segment: 1,
    Family: 2,
    Class: 3,
    Brick: 4,
    Attribute: 5,
    Value: 6,
  };

  return Object.freeze({
    ClassificationLevel,
  });
})();
