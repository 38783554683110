<template>
  <footer class="footer mb-3">
    <div class="container">
      <div class="row gs1-pt-spacer-1 gs1-pb-spacer-1">
        <div class="col-xs-12 col-sm-4 col-md-2">
          <h4><a href="https://www.gs1.org/standards" class="gs1-color-blue">Standards</a></h4>
          <ul>
            <li><a href="https://www.gs1.org/need-gs1-barcode">Need a barcode</a></li>
            <li><a href="https://www.gs1.org/genspecs">General Specifications</a></li>
            <li><a href="https://www.gs1.org/standards/id-keys/gtin">Global Trade Item Number (GTIN)</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <h4><a href="https://www.gs1.org/services" class="gs1-color-blue">Services</a></h4>
          <ul>
            <li><a href="https://www.gs1.org/services/gdsn">GS1 GDSN</a></li>
            <li><a href="http://gepir.gs1.org/">Who owns this barcode?</a></li>
            <li><a href="https://www.gs1.org/check-digit-calculator">Check digit calculator</a></li>
            <li><a href="https://www.gs1.org/services/epc-encoderdecoder">EPC encoder/decoder</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <h4><a href="https://www.gs1.org/industries" class="gs1-color-blue">Industries</a></h4>
          <ul>
            <li><a href="https://www.gs1.org/retail">Retail</a></li>
            <li><a href="https://www.gs1.org/healthcare">Healthcare</a></li>
            <li><a href="https://www.gs1.org/transport-and-logistics">Transport &amp; Logistics</a></li>
            <li><a href="https://www.gs1.org/technical-industries">Technical Industries</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <h4><a href="https://www.gs1.org/news-events" class="gs1-color-blue">News &amp; Events</a></h4>
          <ul>
            <li><a href="https://www.gs1.org/news-archive">News</a></li>
            <li><a href="https://www.gs1.org/events-calendar">Events</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <h4><a href="https://www.gs1.org/about" class="gs1-color-blue">About GS1</a></h4>
          <ul>
            <li><a href="https://www.gs1.org/about/what-we-do">What we do</a></li>
            <li><a href="https://www.gs1.org/how-we-got-here">Our story</a></li>
            <li><a href="https://www.gs1.org/public-policy">Public Policy</a></li>
            <li><a href="https://www.gs1.org/careers">Careers</a></li>
            <li><a href="http://xchange.gs1.org/sites/faq">FAQs</a></li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2">
          <div class="social-media-icons my-4">
            <a href="http://www.twitter.com/gs1" title="Twitter" class="me-3" target="_blank" rel="noopener"
              ><svg enable-background="new 0 0 48 48" viewBox="0 0 48 48" xml:space="preserve">
                <circle cx="24" cy="24" fill="currentColor" r="24" />
                <g>
                  <g>
                    <path
                      d="M36.8,15.4c-0.9,0.5-2,0.8-3,0.9c1.1-0.7,1.9-1.8,2.3-3.1c-1,0.6-2.1,1.1-3.4,1.4c-1-1.1-2.3-1.8-3.8-1.8    c-2.9,0-5.3,2.5-5.3,5.7c0,0.4,0,0.9,0.1,1.3c-4.4-0.2-8.3-2.5-10.9-5.9c-0.5,0.8-0.7,1.8-0.7,2.9c0,2,0.9,3.7,2.3,4.7    c-0.9,0-1.7-0.3-2.4-0.7c0,0,0,0.1,0,0.1c0,2.7,1.8,5,4.2,5.6c-0.4,0.1-0.9,0.2-1.4,0.2c-0.3,0-0.7,0-1-0.1    c0.7,2.3,2.6,3.9,4.9,3.9c-1.8,1.5-4.1,2.4-6.5,2.4c-0.4,0-0.8,0-1.3-0.1c2.3,1.6,5.1,2.6,8.1,2.6c9.7,0,15-8.6,15-16.1    c0-0.2,0-0.5,0-0.7C35.2,17.6,36.1,16.6,36.8,15.4z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g></svg
            ></a>
            <a href="https://www.youtube.com/user/gs1global" title="Youtube" class="me-3" target="_blank" rel="noopener"
              ><svg fill="currentColor" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2" viewBox="0 0 512 512">
                <path
                  d="M256,0c141.29,0 256,114.71 256,256c0,141.29 -114.71,256 -256,256c-141.29,0 -256,-114.71 -256,-256c0,-141.29 114.71,-256 256,-256Zm153.315,178.978c-3.68,-13.769 -14.522,-24.61 -28.29,-28.29c-24.958,-6.688 -125.025,-6.688 -125.025,-6.688c0,0 -100.067,0 -125.025,6.688c-13.765,3.68 -24.61,14.521 -28.29,28.29c-6.685,24.955 -6.685,77.024 -6.685,77.024c0,0 0,52.067 6.685,77.02c3.68,13.769 14.525,24.614 28.29,28.293c24.958,6.685 125.025,6.685 125.025,6.685c0,0 100.067,0 125.025,-6.685c13.768,-3.679 24.61,-14.524 28.29,-28.293c6.685,-24.953 6.685,-77.02 6.685,-77.02c0,0 0,-52.069 -6.685,-77.024Zm-185.316,125.025l0,-96.002l83.137,48.001l-83.137,48.001Z"
                /></svg
            ></a>
            <a href="https://www.linkedin.com/company/gs1" title="LinkedIn" target="_blank" rel="noopener"
              ><svg fill="currentColor" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2" viewBox="0 0 512 512">
                <path
                  d="M256,0c141.29,0 256,114.71 256,256c0,141.29 -114.71,256 -256,256c-141.29,0 -256,-114.71 -256,-256c0,-141.29 114.71,-256 256,-256Zm-80.037,399.871l0,-199.921l-66.464,0l0,199.921l66.464,0Zm239.62,0l0,-114.646c0,-61.409 -32.787,-89.976 -76.509,-89.976c-35.255,0 -51.047,19.389 -59.889,33.007l0,-28.306l-66.447,0c0.881,18.757 0,199.921 0,199.921l66.446,0l0,-111.65c0,-5.976 0.43,-11.95 2.191,-16.221c4.795,-11.935 15.737,-24.299 34.095,-24.299c24.034,0 33.663,18.34 33.663,45.204l0,106.966l66.45,0Zm-272.403,-296.321c-22.74,0 -37.597,14.95 -37.597,34.545c0,19.182 14.405,34.544 36.717,34.544l0.429,0c23.175,0 37.6,-15.362 37.6,-34.544c-0.43,-19.595 -14.424,-34.545 -37.149,-34.545Z"
                /></svg
            ></a>
          </div>
        </div>
      </div>
      <div class="d-sm-block d-md-flex thematic-break">
        <div class="gs1-utility-links mt-2">
          <div class="row g-0 d-md-block">
            <a class="col-12 pe-4 me-4 utility-link" href="https://www.gs1.org/terms-use" title="en">Terms of use</a>
            <a class="col-12 pe-4 me-4 utility-link" href="https://www.gs1.org/privacy-policy" title="en">Privacy policy</a>
            <a
              class="col-12 pe-4 me-4 utility-link"
              href="https://gs1.wufoo.com/forms/website-feedback/def/Field1=https://www.gs1.org/standards/gpc/jun-2019/&amp;Field106=https://www.gs1.org/services"
              title="en"
              >Website feedback</a
            >
            <a class="col-12 me-3" href="https://www.gs1.org/contact" title="en">Contact us</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.social-media-icons svg {
  height: 2.3rem;
  width: 2.3rem;
  color: var(--gs1-color-blue);
  cursor: pointer;
}
.social-media-icons svg:hover {
  color: var(--gs1-color-link);
}
.gs1-utility-links a {
  font-size: 0.875rem;
}
.gs1-utility-links a.utility-link {
  border-right: 1px solid #b1b3b3;
}
.footer h4 a {
  color: var(--gs1-color-blue);
  font-weight: 500;
  font-size: 1.1875rem;
}
.footer h4 a:hover {
  text-decoration: underline;
}
.footer ul {
  list-style-type: none;
  margin-bottom: 20px;
  padding: 0;
  line-height: 1em;
}
.footer a {
  color: var(--gs1-color-link);
  font-weight: 300;
  text-decoration: none;
  font-size: 0.875rem;
}
.thematic-break{
border-top:1px solid #b3b3b3;
}
</style>
