<template>
<div class="container">
  <div v-if="isReleaseNoteAvailable" class="row gs1-navigator-spacer pt-0">
      <div class="col-12 d-flex align-items-center">
        <a class="btn gs1-button orange" :href="downloadAttachmentHref">{{ releaseNoteTitle }}<BIconDownload class="ms-3" /></a>
      </div>
    </div>
    <div v-if="isCurrentAvailable && !isCurrentGDSN" class="row gpc">
      <div class="col-6">
        <div class="gs1-list-group list-group">
          <h5 class="gs1-h5 gs1-color-blue m-0 gs1-list-group-item list-group-item">
            <div>{{ currentPublicationTitle }}</div>
          </h5>
          <a
            @click.prevent="downloadGPCPublciationAsJson"
            :href="downloadHref"
            :class="{
              disabled:
                downloadState.isDownloadingGPCAsXml ||
                downloadState.isDownloadingGPCAsXlsx ||
                downloadState.isDownloadingDeltaAsJson ||
                downloadState.isDownloadingDeltaAsXlsx,
            }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GPC as JSON
            <span
              ><span v-show="downloadState.isDownloadingGPCAsJson" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGPCAsJson" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGPCAsJson" /></span
          ></a>
          <a
            @click.prevent="downloadGPCPublciationAsXml"
            :href="downloadHref"
            :class="{
              disabled:
                downloadState.isDownloadingGPCAsJson ||
                downloadState.isDownloadingGPCAsXlsx ||
                downloadState.isDownloadingDeltaAsJson ||
                downloadState.isDownloadingDeltaAsXlsx,
            }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GPC as XML<span
              ><span v-show="downloadState.isDownloadingGPCAsXml" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGPCAsXml" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGPCAsXml" /></span
          ></a>
          <a
            @click.prevent="downloadGPCPublciationAsXlsx"
            :href="downloadHref"
            :class="{
              disabled:
                downloadState.isDownloadingGPCAsXml ||
                downloadState.isDownloadingGPCAsJson ||
                downloadState.isDownloadingDeltaAsJson ||
                downloadState.isDownloadingDeltaAsXlsx,
            }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GPC as XLSX<span
              ><span v-show="downloadState.isDownloadingGPCAsXlsx" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGPCAsXlsx" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGPCAsXlsx" /></span
          ></a>
        </div>
      </div>
      <div v-if="isDeltaAvailable" class="col-6">
        <div class="gs1-list-group list-group">
          <h5 class="gs1-h5 gs1-color-blue m-0 gs1-list-group-item list-group-item">{{ currentPublicationDeltaTitle }}</h5>
          <a
            @click.prevent="downloadDeltaPublicationAsJson"
            :href="downloadHref"
            :class="{
              disabled:
                downloadState.isDownloadingGPCAsJson ||
                downloadState.isDownloadingGPCAsXml ||
                downloadState.isDownloadingGPCAsXlsx ||
                downloadState.isDownloadingDeltaAsXlsx,
            }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download Delta as JSON
            <span
              ><span v-show="downloadState.isDownloadingDeltaAsJson" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingDeltaAsJson" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingDeltaAsJson" /> </span
          ></a>
          <a
            @click.prevent="downloadDeltaPublicationAsXlsx"
            :href="downloadHref"
            :class="{
              disabled:
                downloadState.isDownloadingGPCAsJson ||
                downloadState.isDownloadingGPCAsXml ||
                downloadState.isDownloadingGPCAsXlsx ||
                downloadState.isDownloadingDeltaAsJson,
            }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download Delta as XLSX
            <span
              ><span v-show="downloadState.isDownloadingDeltaAsXlsx" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingDeltaAsXlsx" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingDeltaAsXlsx" /></span
          ></a>
        </div>
      </div>
    </div>
    <div v-if="isCurrentAvailable && isCurrentGDSN" class="row gdsn">
      <div class="col-6">
        <div class="gs1-list-group list-group">
          <h5 class="gs1-h5 gs1-color-blue m-0 gs1-list-group-item list-group-item">
            <div>{{ currentPublicationTitle }}</div>
          </h5>
          <a
            @click.prevent="downloadGdsnPublciationAsJson"
            :href="downloadHref"
            :class="{ disabled: downloadState.isDownloadingGDSNAsXml || downloadState.isDownloadingGDSNAsXlsx }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GDSN as JSON
            <span
              ><span v-show="downloadState.isDownloadingGDSNAsJson" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGDSNAsJson" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGDSNAsJson" /> </span
          ></a>
          <a
            @click.prevent="downloadGdsnPublciationAsXml"
            :href="downloadHref"
            :class="{ disabled: downloadState.isDownloadingGDSNAsJson || downloadState.isDownloadingGDSNAsXlsx }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GDSN as XML
            <span
              ><span v-show="downloadState.isDownloadingGDSNAsXml" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGDSNAsXml" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGDSNAsXml" /></span
          ></a>
          <a
            @click.prevent="downloadGdsnPublciationAsXlsx"
            :href="downloadHref"
            :class="{ disabled: downloadState.isDownloadingGDSNAsJson || downloadState.isDownloadingGDSNAsXml }"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Download GDSN as XLSX
            <span
              ><span v-show="downloadState.isDownloadingGDSNAsXlsx" class="spinner-download spinner-border gs1-color-orange" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <BIconDownload v-show="isFreeToDownload && !downloadState.isDownloadingGDSNAsXlsx" />
              <BIconEnvelope v-show="!isFreeToDownload && !downloadState.isDownloadingGDSNAsXlsx" /></span
          ></a>
        </div>
      </div>
    </div>
    <div v-if="!isCurrentAvailable" class="row pt-0">
      <div class="col-12">
        <div class="gs1-list-group list-group">
          <a
            :href="mailToMoHref"
            class="
              gs1-list-group-item gs1-list-group-item-action
              list-group-item list-group-item-action
              d-flex
              justify-content-between
              align-items-center
            "
            >Translation are managed by the GS1 Member Organisation Offices and schedules vary. Please contact translating MO with questions.<span
              ><BIconEnvelope /></span
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconDownload, BIconEnvelope } from "bootstrap-icons-vue";
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";
import usePublication from "../../composables/usePublication";
import fileService from "../../services/fileService";
import useGtm from "../../composables/useGtm";

export default {
  components: { BIconDownload, BIconEnvelope },
  props: {
    selectedLanguage: Object,
    selectedPublication: Object,
  },
  setup(props) {
    const {
      getPreviousPublicationAsync,
      isLatestPublicationAsync,
      getDownloadPublicationReleaseNoteAttachmentUrl,
      downloadPublicationAsJsonAsync,
      downloadPublicationAsXmlAsync,
      downloadPublicationAsXlsxAsync,
      downloadDeltaAsJsonAsync,
      downloadDeltaAsXlsxAsync,
    } = usePublication();
    const { trackEvent } = useGtm();

    const selectedLanguageName = computed(() => props.selectedLanguage.languageName);
    const currentPublication = ref(null);
    const previousPublication = ref(null);
    const isLatestPublication = ref(false);

    const isCurrentAvailable = computed(() => currentPublication.value != null && isLatestPublication.value);
    const isCurrentGDSN = computed(() => isCurrentAvailable.value && currentPublication.value.isGDSN);
    const isPreviousAvailable = computed(() => previousPublication.value != null);
    const isDeltaAvailable = computed(() => !isCurrentGDSN.value && isPreviousAvailable.value);
    const isReleaseNoteAvailable = computed(
      () => isCurrentAvailable.value && currentPublication.value.releaseNoteAttachment != null && currentPublication.value.releaseNoteAttachment != ""
    );
    const isFreeToDownload = computed(() => props.selectedLanguage.freeToDownload);

    const currentPublicationTitle = computed(() => {
      if (isCurrentAvailable.value && !isCurrentGDSN.value) {
        return `${currentPublication.value.publicationName} (${currentPublication.value.version})`;
      }
      if (isCurrentGDSN.value) {
        return `GPC version in GDSN (${currentPublication.value.publicationName} ${currentPublication.value.version})`;
      }
      return "";
    });
    const currentPublicationDeltaTitle = computed(() => {
      if (isCurrentAvailable.value && isPreviousAvailable.value) {
        return `${currentPublication.value.publicationName} (${currentPublication.value.version}) v. ${previousPublication.value.publicationName} (${previousPublication.value.version})`;
      }
      return "";
    });

    const donwnloadMainLabel = computed(() =>
      isCurrentAvailable.value
        ? `Download ${selectedLanguageName.value} ${currentPublication.value.publicationName} (${currentPublication.value.version})`
        : ""
    );
    const releaseNoteTitle = computed(() =>
      isReleaseNoteAvailable.value
        ? `Download ${currentPublication.value.publicationName} (${currentPublication.value.version}) Release Notes PDF`
        : ""
    );

    const downloadHref = computed(() => (!isFreeToDownload.value ? `mailto:${props.selectedLanguage.moContactEmail}` : "#"));
    const mailToMoHref = computed(() => (props.selectedLanguage.moContactEmail ? `mailto:${props.selectedLanguage.moContactEmail}` : "#"));
    const downloadAttachmentHref = computed(() => getDownloadPublicationReleaseNoteAttachmentUrl(currentPublication.value.publicationId));

    const downloadState = ref({
      isDownloadingGPCAsJson: false,
      isDownloadingGPCAsXml: false,
      isDownloadingGPCAsXlsx: false,
      isDownloadingGDSNAsJson: false,
      isDownloadingGDSNAsXml: false,
      isDownloadingGDSNAsXlsx: false,
      isDownloadingDeltaAsJson: false,
      isDownloadingDeltaAsXlsx: false,
    });

    watch(
      () => props.selectedPublication,
      async (publication) => {
        currentPublication.value = publication;
        if (publication != null) {
          const previousPublicationResponse = await getPreviousPublicationAsync(publication.publicationId);
          previousPublication.value = previousPublicationResponse.isSuccess ? previousPublicationResponse.result : null;
          const isLatestPublicationResponse = await isLatestPublicationAsync(publication.publicationId);
          isLatestPublication.value = isLatestPublicationResponse.result;
        }
      }
    );

    const downloadGPCPublciationAsJson = async (event) => {
      if (downloadState.value.isDownloadingGPCAsJson || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGPCAsJson = true;
      let data = await downloadPublicationAsJsonAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadJson(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gpc",
          type: "json",
        });
      } else {
        currentPublication.value = null;
      }
      downloadState.value.isDownloadingGPCAsJson = false;
    };

    const downloadGPCPublciationAsXml = async (event) => {
      if (downloadState.value.isDownloadingGPCAsXml || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGPCAsXml = true;
      let data = await downloadPublicationAsXmlAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadXml(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gpc",
          type: "xml",
        });
      } else {
        currentPublication.value = null;
      }
      downloadState.value.isDownloadingGPCAsXml = false;
    };

    const downloadGPCPublciationAsXlsx = async (event) => {
      if (downloadState.value.isDownloadingGPCAsXlsx || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGPCAsXlsx = true;
      let data = await downloadPublicationAsXlsxAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadXlsx(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gpc",
          type: "xlsx",
        });
      } else {
        currentPublication.value = null;
      }
      downloadState.value.isDownloadingGPCAsXlsx = false;
    };

    const downloadGdsnPublciationAsJson = async (event) => {
      if (downloadState.value.isDownloadingGDSNAsJson || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGDSNAsJson = true;
      let data = await downloadPublicationAsJsonAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadJson(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gdsn",
          type: "xlsx",
        });
      } else {
        currentPublication.value = null;
      }

      downloadState.value.isDownloadingGDSNAsJson = false;
    };

    const downloadGdsnPublciationAsXml = async (event) => {
      if (downloadState.value.isDownloadingGDSNAsXml || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGDSNAsXml = true;
      let data = await downloadPublicationAsXmlAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadXml(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gdsn",
          type: "xml",
        });
      } else {
        currentPublication.value = null;
      }

      downloadState.value.isDownloadingGDSNAsXml = false;
    };

    const downloadGdsnPublciationAsXlsx = async (event) => {
      if (downloadState.value.isDownloadingGDSNAsXlsx || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingGDSNAsXlsx = true;
      let data = await downloadPublicationAsXlsxAsync(currentPublication.value.publicationId, props.selectedLanguage.usePublicationBlobs);
      if (data.isSuccess) {
        fileService.downloadXlsx(data.name, data.file);
        trackEvent("gpc_download_file", {
          languageCode: props.selectedLanguage.languageCode.toLowerCase(),
          version: "gdsn",
          type: "xlsx",
        });
      } else {
        currentPublication.value = null;
      }
      downloadState.value.isDownloadingGDSNAsXlsx = false;
    };

    const downloadDeltaPublicationAsJson = async (event) => {
      if (downloadState.value.isDownloadingDeltaAsJson || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingDeltaAsJson = true;
      let data = await downloadDeltaAsJsonAsync(props.selectedLanguage.languageId, currentPublication.value.publicationId);
      fileService.downloadJson(data.name, data.file);
      downloadState.value.isDownloadingDeltaAsJson = false;
      trackEvent("delta_download_file", {
        languageCode: props.selectedLanguage.languageCode.toLowerCase(),
        type: "json",
      });
    };

    const downloadDeltaPublicationAsXlsx = async (event) => {
      if (downloadState.value.isDownloadingDeltaAsXlsx || !isFreeToDownload.value) {
        return;
      }
      event.preventDefault();
      downloadState.value.isDownloadingDeltaAsXlsx = true;
      let data = await downloadDeltaAsXlsxAsync(props.selectedLanguage.languageId, currentPublication.value.publicationId);
      fileService.downloadXlsx(data.name, data.file);
      downloadState.value.isDownloadingDeltaAsXlsx = false;
      trackEvent("delta_download_file", {
        languageCode: props.selectedLanguage.languageCode.toLowerCase(),
        type: "xlsx",
      });
    };

    return {
      downloadState,
      downloadGPCPublciationAsJson,
      downloadGPCPublciationAsXml,
      downloadGPCPublciationAsXlsx,
      downloadGdsnPublciationAsJson,
      downloadGdsnPublciationAsXml,
      downloadGdsnPublciationAsXlsx,
      downloadDeltaPublicationAsJson,
      downloadDeltaPublicationAsXlsx,
      isCurrentAvailable,
      isPreviousAvailable,
      isDeltaAvailable,
      isCurrentGDSN,
      isFreeToDownload,
      isReleaseNoteAvailable,
      downloadHref,
      mailToMoHref,
      selectedLanguageName,
      currentPublicationTitle,
      currentPublicationDeltaTitle,
      releaseNoteTitle,
      downloadAttachmentHref,
      donwnloadMainLabel,
    };
  },
};
</script>
