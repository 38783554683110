<template>
  <ul class="m-0 p-0 tree">
    <li v-if="isItemOutOfSearch" class="tree-node mb-0" :class="{ 'has-child': isFolder, loading: isLoadingTreeItem }">
      <div class="tree-content rounded">
        <div @click="toggle" class="d-flex">
          <BIconChevronDown v-show="isOpen && !isLoadingTreeItem" class="tree-arrow" :class="{ expanded: isOpen, 'has-child': isFolder }"></BIconChevronDown>
          <BIconChevronRight v-show="!isOpen && !isLoadingTreeItem" class="tree-arrow" :class="{ expanded: isOpen, 'has-child': isFolder }"></BIconChevronRight>
          <div v-show="isLoadingTreeItem" class="spinner-border gs1-color-orange mx-2 fs-6" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="tree-item-main">
          <span @click="toggle" class="item-level">{{ getLevelTitle(item.level) }} </span>
          <span class="item-code" v-html="treeItemCode"></span>
          <span class="item-title" v-html="treeItemTitle"></span>
          <span v-if="isDefinitionPresent" @click="toggleDefinition" class="info">
            <BIconCaretDownSquare v-if="!isDefinitionOpen"></BIconCaretDownSquare>
            <BIconCaretUpSquare v-if="isDefinitionOpen" class="gs1-color-blue"></BIconCaretUpSquare>
          </span>
        </div>
      </div>
      <div v-if="isDefinitionPresent" class="d-flex definition-block align-items-center rounded">
        <transition name="fade">
          <div v-if="isDefinitionOpen" class="item-definition rounded">
            <div class="item-definition-includes">
              <span class="fw-bold">Definition: </span>
              <span v-html="treeItemDefinition"></span>
            </div>
            <div v-show="isDefinitionExcludesPresent" class="item-definition-excludes">
              <span class="fw-bold">Definition Excludes: </span>
              <span v-html="treeItemDefinitionExcludes"></span>
            </div>
          </div>
        </transition>
      </div>
      <transition name="fade">
        <ul v-if="isOpen && isFolder" class="tree-children">
          <tree-item v-for="child in item.childs" :key="child.code" :item="child"></tree-item>
        </ul>
      </transition>
    </li>
  </ul>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import useTree from "../../composables/useTree";
import { BIconChevronDown, BIconChevronRight, BIconCaretDownSquare, BIconCaretUpSquare } from "bootstrap-icons-vue";
export default {
  components: { BIconChevronDown, BIconChevronRight, BIconCaretDownSquare, BIconCaretUpSquare },
  name: "tree-item",
  props: { item: Object },
  setup(props) {
    const { getLevelTitle, isFetchingAttributesAndValues, isLoading } = useTree();
    const isOpen = ref(false);
    const isDefinitionOpen = ref(false);

    const isFolder = computed(() => {
      return props.item.childs && props.item.childs.length;
    });

    const isLoadingTreeItem = computed(() => {
      if (isFetchingAttributesAndValues.value) {
        return isLoading(props.item.level);
      }
      return false;
    });

    const isItemOutOfSearch = computed(() => {
      return !props.item.isOutOfSearch || props.item.isOutOfSearch == null;
    });

    const treeItemCode = computed(() => {
      if (props.item.codeSearch == null || props.item.codeSearch == "") {
        return props.item.code;
      }
      return props.item.codeSearch;
    });

    const treeItemTitle = computed(() => {
      if (props.item.titleSearch == null || props.item.titleSearch == "") {
        return props.item.title;
      }
      return props.item.titleSearch;
    });

    const treeItemDefinition = computed(() => {
      if (props.item.definitionSearch == null || props.item.definitionSearch == "") {
        return props.item.definition;
      }
      return props.item.definitionSearch;
    });

    const treeItemDefinitionExcludes = computed(() => {
      if (props.item.definitionExcludesSearch == null || props.item.definitionExcludesSearch == "") {
        return props.item.definitionExcludes;
      }
      return props.item.definitionExcludesSearch;
    });

    const isDefinitionPresent = computed(() => {
      return treeItemDefinition.value != null && treeItemDefinition.value !== "";
    });

    const isDefinitionExcludesPresent = computed(() => {
      return treeItemDefinitionExcludes.value != null && treeItemDefinitionExcludes.value !== "";
    });

    onMounted(() => {
      if (props.item.isOpen) {
        isOpen.value = true;
      }
      if (props.item.definitionSearch != null && props.item.definitionSearch !== "") {
        isDefinitionOpen.value = true;
      }
      if (props.item.definitionExcludesSearch != null && props.item.definitionExcludesSearch !== "") {
        isDefinitionOpen.value = true;
      }
    });

    const toggle = () => {
      if (isFolder.value) {
        isOpen.value = !isOpen.value;
      }
    };
    const toggleDefinition = () => {
      if (isDefinitionPresent.value) {
        isDefinitionOpen.value = !isDefinitionOpen.value;
      }
    };

    return {
      isOpen,
      isFolder,
      isLoadingTreeItem,
      isItemOutOfSearch,
      toggle,
      toggleDefinition,
      getLevelTitle,
      treeItemCode,
      treeItemTitle,
      treeItemDefinition,
      treeItemDefinitionExcludes,
      isDefinitionPresent,
      isDefinitionExcludesPresent,
      isDefinitionOpen,
    };
  },
};
</script>

<style scoped>
.tree {
  font-size: .875rem;
}
.tree-children {
  margin-bottom: 0;
  padding-left: 3rem !important;
}
.tree-node {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}
.tree-content {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0;
  cursor: default;
  width: 100%;
  box-sizing: border-box;
}
.tree-content .spinner-border {
  height: 1rem;
  width: 1rem;
  border-width: 0.1em;
}
.tree-node:not(.selected) > .tree-content:hover {
  background: var(--gs1-color-ui-2);
}
.tree-node.selected > .tree-content {
  background-color: #e7eef7;
}
.tree-node.disabled > .tree-content:hover {
  background: inherit;
}
.tree-node.loading .tree-arrow {
  margin-left: 0;
  width: 30px;
  position: relative;
}
.tree-arrow {
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 30px;
  width: 0;
}
.tree-arrow.has-child {
  margin-left: 0;
  width: 30px;
  position: relative;
}
.tree-arrow.has-child:after {
  border: 1.5px solid #494646;
  position: absolute;
  border-left: 0;
  border-top: 0;
  left: 9px;
  top: 50%;
  height: 9px;
  width: 9px;
  transform: rotate(-45deg) translateY(-50%) translateX(0);
  transition: transform 0.25s;
  transform-origin: center;
}
.tree-arrow.has-child.rtl:after {
  border: 1.5px solid #494646;
  position: absolute;
  border-right: 0;
  border-bottom: 0;
  right: 0px;
  top: 50%;
  height: 9px;
  width: 9px;
  transform: rotate(-45deg) translateY(-50%) translateX(0);
  transition: transform 0.25s;
  transform-origin: center;
}
.tree-arrow.expanded.has-child:after {
  transform: rotate(45deg) translateY(-50%) translateX(-5px);
}
.tree-arrow:after {
  position: absolute;
  display: block;
  content: "";
}
.tree-arrow:hover {
  color: var(--gs1-color-blue);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(0);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-2em);
}

.item-level {
  color: var(--gs1-color-ui-6);
  margin-right: 10px;
  cursor: pointer;
}
.item-level:hover {
  color: var(--gs1-color-blue);
}
.item-code {
  margin-right: 10px;
  font-weight: 500;
}
.item-title {
  margin-right: 10px;
}
.search-keyword {
  color: white;
  background-color: #212529 !important;
}
.info {
  margin-right: 10px;
  cursor: pointer;
}
.info > svg {
  width: 20px;
  height: 20px;
  color: var(--gs1-color-ui-6);
}
.definition-block {
  margin-left: 1.875rem;
  color: var(--gs1-color-blue);
  background: var(--gs1-color-ui-1);
}
.info:hover > svg {
  color: var(--gs1-color-blue);
}
.item-definition {
  padding: 0.5rem;
}
.tree-item-main {
  white-space: nowrap !important;
  overflow-wrap: break-word;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .tree-item-main {
    white-space: initial !important;
  }
}
</style>
