<template>
  <button
    id="LanguageDropdown"
    class="btn btn-outline-secondary gs1-dropdown"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    :title="selectedLanguageName"
  >
    <span class="lang-label">{{ selectedLanguageName }}</span
    ><b-icon-chevron-down class="ms-1 gs1-color-blue"></b-icon-chevron-down>
  </button>
  <ul class="dropdown-menu gs1-dropdown-menu">
    <li v-for="language in languages" :key="language.languageId" @click.prevent="selectLanguage(language.languageId)" class="m-0">
      <a class="dropdown-item" href="#">{{ language.languageName }}</a>
    </li>
  </ul>
</template>

<script>
import { computed, nextTick, onMounted, ref, watch } from "@vue/runtime-core";
import useLanguage from "../../composables/useLanguage";
import useGtm from "../../composables/useGtm";
import { BIconChevronDown } from "bootstrap-icons-vue";
import { useRoute } from "vue-router";
export default {
  components: { BIconChevronDown },
  emits: ["onChange"],
  setup(props, { emit }) {
    const { languages, fetchLanguagesAsync } = useLanguage();
    const { trackEvent } = useGtm();
    const route = useRoute();

    const selectedLanguageId = ref(0);
    const selectedLanguageName = computed(() => {
      let lang = languages.value.find((f) => f.languageId == selectedLanguageId.value);
      return lang == null ? "Language" : lang.languageName;
    });

    watch(selectedLanguageId, (currLanguageId, prevLanguageId) => {
      if (currLanguageId != prevLanguageId) {
        emit("onChange", currLanguageId);
        trackEvent("gpc_language_select", {
          languageCode: languages.value.find((f) => f.languageId == currLanguageId).languageCode.toLowerCase(),
        });
      }
    });

    onMounted(async () => {
      await fetchLanguagesAsync();
      await nextTick();
      detectUserLanguage();
      handleQueryParamLanguage();
    });

    const selectLanguage = (languageId) => {
      selectedLanguageId.value = languageId;
    };

    const detectUserLanguage = () => {
      const browserCulture = navigator.language || navigator.userLanguage;
      if (!browserCulture) {
        return;
      }
      const correspondingLanguageByCulture = languages.value.find((f) => f.culture == browserCulture);
      if (correspondingLanguageByCulture) {
        selectedLanguageId.value = correspondingLanguageByCulture.languageId;
        return;
      }
      const correspondingLanguageByLanguageCode = languages.value.find(
        (f) => f.languageCode.toLowerCase() == browserCulture.substring(0, 2).toLowerCase()
      );
      if (correspondingLanguageByLanguageCode) {
        selectedLanguageId.value = correspondingLanguageByLanguageCode.languageId;
        return;
      }
      const engLanguage = languages.value.find((f) => f.languageCode.toLowerCase() === "en");
      if (engLanguage) {
        selectedLanguageId.value = engLanguage.languageId;
      }
    };

    const handleQueryParamLanguage = () => {
      const langParam = route.query.lang;
      if (!langParam) return;
      const matchedLanguage = languages.value.find((f) => f.languageCode.toLowerCase() == langParam);
      if (matchedLanguage) {
        selectedLanguageId.value = matchedLanguage.languageId;
      }
    };

    return {
      selectedLanguageId,
      selectedLanguageName,
      selectLanguage,
      languages,
    };
  },
};
</script>
<style scoped>
#LanguageDropdown {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lang-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
