<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
          <ol class="breadcrumb my-2 fs-6">
            <li class="breadcrumb-item"><a href="https://www.gs1.org">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Browser</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.breadcrumb-item{
  margin-bottom: 0;
  text-decoration: none;
}
.breadcrumb-item a{
  text-decoration: none;
}
</style>
