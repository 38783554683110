<template>
  <div class="gs1-top-header">
    <div class="container">
      <div v-if="showSearchBar" class="row height d-flex justify-content-center align-items-center" style="height: 60px">
        <div class="col-md-12">
          <div class="gs1-global-search">
            <BIconSearch style="height: 16px" class="gs1-search-icon" />
            <input v-model="textToSearch" @keyup.enter="doSearch" type="text" class="form-control" placeholder="Search gs1.org" />
            <span class="close-button" @click="textToSearch = ''">&#x2716;</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-content-center py-1">
        <div class="main-text px-1">The Global Language of Business</div>
        <div class="mo-zone-login-link px-2"><a href="https://mozone.gs1.org/home/" target="_blank" rel="noopener">MO Zone login</a></div>
        <div class="contact-us-link px-2"><a href="https://www.gs1.org/contact">Contact us</a></div>
        <div class="ps-2 d-flex" @click="showSearchBar = !showSearchBar"><BIconSearch style="height: 16px" class="gs1-search-icon" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconSearch } from "bootstrap-icons-vue";
import { ref } from "@vue/reactivity";
export default {
  components: { BIconSearch },
  setup() {
    const showSearchBar = ref(false);
    const textToSearch = ref("");
    const doSearch = () => {
      if (textToSearch.value == null || textToSearch.value == "") {
        return;
      }
      const encodedParam = encodeURIComponent(textToSearch.value);
      window.location.href = `https://www.gs1.org/search/gsone/${encodedParam}`;
    };
    return { showSearchBar, textToSearch , doSearch};
  },
};
</script>

<style scoped>
.gs1-top-header {
  background-color: var(--gs1-color-ui-1);
  box-shadow: 0 2px 3px 0 rgb(0 44 108 / 20%);
}
.gs1-top-header .main-text {
  font-size: 0.75rem;
  font-weight: 500;
}
.mo-zone-login-link {
  font-size: 0.75rem;
  font-weight: 500;
}
.contact-us-link {
  font-size: 0.75rem;
  font-weight: 500;
}
.gs1-search-icon {
  height: 16px;
  color: var(--gs1-color-blue);
  cursor: pointer;
}
.gs1-global-search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.gs1-global-search input {
  text-indent: 30px;
}
.gs1-global-search input:focus {
  box-shadow: none;
  border: 2px solid var(--gs1-color-blue);
}

.gs1-global-search .gs1-search-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.gs1-global-search .close-button {
  position: absolute;
  top: 5px;
  right: 15px;
  font-weight: bolder;
  color: #999;
  cursor: pointer;
}
</style>
