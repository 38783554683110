<template>
  <input v-model="searchText" @keyup.enter="doSearch" type="search" class="form-control gs1-input" placeholder="Search..." autocomplete="off" />
  <button @click="doSearch" class="btn btn-outline-secondary gs1-button blue" type="button" :disabled="!isSearchEnabled">
    <b-icon-search class="fs-5"></b-icon-search>
  </button>
</template>

<script>
import { ref } from "@vue/reactivity";
import useTree from "../../composables/useTree";
import useSearch from "../../composables/useSearch";
import { computed, watchEffect, watch } from "@vue/runtime-core";
import { BIconSearch } from "bootstrap-icons-vue";
import useGtm from "../../composables/useGtm";
import { useRoute } from "vue-router";
export default {
  components: { BIconSearch },
  emits: ["onSearch"],
  props: { treeData: Array },
  setup(props, { emit }) {
    const { isFetchingBricks } = useTree();
    const { isSearching } = useSearch();
    const { trackEvent } = useGtm();
    const route = useRoute();

    const MIN_SEARCH_LENGTH = 3;
    const searchText = ref(route.query.search ?? "");
    const isSearchValid = ref("");
    let isRouteParamHandled = false

    const isSearchEnabled = computed(() => {
      return isSearchValid.value && !isFetchingBricks.value && !isSearching.value;
    });

    watchEffect(() => {
      if (searchText.value == "" || searchText.value.length >= MIN_SEARCH_LENGTH) {
        isSearchValid.value = true;
      } else {
        isSearchValid.value = false;
      }
    });

    watch(isSearchEnabled, (value)=> value && handleSearchQueryParam());

    const doSearch = () => {
      if (!isSearchEnabled.value) {
        return;
      }
      emit("onSearch", searchText.value);
      trackEvent("gpc_browser_search", {
        text: searchText.value,
      });
    };

    const handleSearchQueryParam = () => {
      if(isRouteParamHandled) return;
      searchText.value && doSearch();
      isRouteParamHandled = true;
    };

    return { searchText, doSearch, isSearchEnabled, isSearching };
  },
};
</script>
